import { useEmitter } from '@cocoonspace/shared/hooks/use-emitter.hook'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { Dialog, DialogContent, DialogTitle } from '~/components/ui/dialog'
import { dialogsEmitter } from '~/emitters/dialogs.emitter'
import { TimeSlotsTable } from './time-slots-table'

export const CheckTimeSlotsDialog = () => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [isQuotationEditPage, setIsQuotationEditPage] = useState(false)

	useEmitter(
		dialogsEmitter,
		{
			'availabilities.open': (config) => {
				setIsOpen(true)
				setIsQuotationEditPage(!!config?.isQuotationEditPage)
			},
			'availabilities.close': () => setIsOpen(false),
		},
		[setIsOpen, setIsQuotationEditPage],
	)

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogContent className='!rounded-none flex h-screen w-screen flex-col'>
				<DialogTitle>
					{t('quotations:availabilities.checkAvailTitle')}
				</DialogTitle>

				<div className='flex flex-1 flex-col overflow-hidden'>
					<TimeSlotsTable isQuotationEditPage={isQuotationEditPage} />
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default CheckTimeSlotsDialog
