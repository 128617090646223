import type { DateTimeSlot } from '../_types/date-time-slot'

export const sortSlotsByStartDates = (
	a: Pick<DateTimeSlot, 'day' | 'start'>,
	b: Pick<DateTimeSlot, 'day' | 'start'>,
) => {
	const aDate = new Date(`${a.day} ${a.start}`).getTime()
	const bDate = new Date(`${b.day} ${b.start}`).getTime()

	return aDate < bDate ? -1 : 1
}
