import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { Trash2 } from 'lucide-react'
import { Button } from '~/components/ui/button'
import type { DateTimeSlot } from '../../_types/date-time-slot'

export const DateTimeColumnHeader = ({
	dateTimeSlot,
	onDelete,
}: {
	dateTimeSlot: DateTimeSlot
	onDelete: () => void
}) => (
	<div className='flex flex-row items-center gap-1 leading-none'>
		<div data-testid='date-container' className='flex-1 text-sm leading-none'>
			{dateTimeUtils(dateTimeSlot.day).customFormat('date')}

			<div className='m-0 text-xxs leading-none'>
				{`${dateTimeSlot.start} - ${dateTimeSlot.end}`}
			</div>
		</div>

		<Button
			variant='ghost'
			size='icon'
			data-testid='delete-btn'
			className='h-6 w-6 rounded-[5px] p-0'
			onClick={() => onDelete()}
		>
			<Trash2 className='size-4' />
		</Button>
	</div>
)
